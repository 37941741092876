// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-jumbotron {
    background-color: #f8f9fa;
    padding: 80px 0;
    text-align: center;
  }
  
  .custom-jumbotron h1 {
    font-size: 3rem;
    margin-bottom: 20px;
  }
  
  .custom-jumbotron p {
    font-size: 1.5rem;
    margin-bottom: 40px;
  }
  
  .custom-jumbotron button {
    font-size: 1.2rem;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/CustomJumbotron.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,eAAe;IACf,kBAAkB;EACpB;;EAEA;IACE,eAAe;IACf,mBAAmB;EACrB;;EAEA;IACE,iBAAiB;IACjB,mBAAmB;EACrB;;EAEA;IACE,iBAAiB;EACnB","sourcesContent":[".custom-jumbotron {\n    background-color: #f8f9fa;\n    padding: 80px 0;\n    text-align: center;\n  }\n  \n  .custom-jumbotron h1 {\n    font-size: 3rem;\n    margin-bottom: 20px;\n  }\n  \n  .custom-jumbotron p {\n    font-size: 1.5rem;\n    margin-bottom: 40px;\n  }\n  \n  .custom-jumbotron button {\n    font-size: 1.2rem;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
